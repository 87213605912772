
import Api from '@/services/Api';
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "RecuperarSenha",
  setup() {
    const route = useRoute();
    const { showTimeAlert } = useAlert();        
    const equal: Ref<boolean> = ref(false)
    const senha1 = ref('')
    const senha2 = ref('')
    const email = ref('');
    const token = ref('');

    const submitForm = async () => {
      const objPass = {email: email.value, token: token.value, password: senha1.value}
      const res = await Api.post('resetPassword', {...objPass})
      if(res.status !== 200) return showTimeAlert(res.msg, 'error');
      return showTimeAlert(res.msg, 'success');
    };

    watch(()=> [senha2.value, senha1.value], ()=> {
      if(senha1.value === senha2.value) equal.value = true
      else equal.value = false
    })

    onMounted(() => {
      if (route.query.email) {
        email.value = route.query.email.toString();
        token.value = route.query.token && route.query.token.toString() || '';
      } else {
        console.error("Parâmetros de email e token não encontrados na URL.");
      }
    })

    const passwordFormRef = ref(null);

    return {
      passwordFormRef,
      submitForm,
      senha1,
      senha2,
      equal
    };
  }
});
